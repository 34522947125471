<template>
  <sign-page
    title-text="过期预警"
    :request="request"
    :title-menus="[{key: 'send_msg', label: '语音通知'}]"
    :table-actions="actions"
    :column-list="columnList"
    @clickTitleMenu="clickTitleMenu"
    table-size="large"
    :dealFormData="dealFormData"
    :tableActionsFixed="true"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :table-actions-width="60"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  agreementRequest as request
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '合同类型',
          selectDatas: this.$store.getters.selectDatas['agreement_type_list'],
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '合同编号',
          key: 'code'
        },
        {
          type: 'select',
          label: '所属员工',
          key: 'workerId',
          selectDatas: getSelectWorkerList(this),
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '签订方式',
          key: 'signedType',
          selectDatas: this.$store.getters.selectDatas['agreement_signed_type_list'],
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '聘用单位',
          key: 'signedOrg'
        },
        {
          type: 'input',
          label: '派遣单位',
          key: 'title',
          show: (fData) => {
            return fData && fData.type === '劳务派遣合同'
          }
        },
        {
          type: 'datePicker',
          label: '签订时间',
          key: 'signedTime'
        },
        {
          type: 'select',
          label: '是否有试用期',
          key: 'needTry',
          selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '试用期开始时间',
          key: 'tryStartDate',
          show: (formData) => {
            return formData.needTry === '是'
          },
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '试用期结束时间',
          key: 'tryEndDate',
          show: (formData) => {
            return formData.needTry === '是'
          },
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '起始时间',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '到期时间',
          key: 'endDate'
        },
        {
          type: 'datePicker',
          label: '终止时间',
          key: 'stopDate'
        },
        {
          type: 'input',
          label: '终止原因',
          key: 'stopReson'
        },
        {
          type: 'datePicker',
          label: '解除时间',
          key: 'disDate'
        },
        {
          type: 'input',
          label: '解除原因',
          key: 'disReson'
        },
        {
          type: 'input',
          label: '合同状态',
          key: 'status'
        },
        {
          type: 'input',
          label: '变更原因',
          key: 'cReson'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    actions () {
      return [{
        key: 'edit',
        label: '处理',
        dealFun: (vm, data) => {
          vm.chooseData = {
            workerId: data.workerId
          }
          vm.formParms = vm.formParmsUpdate
          vm.openDialog = true
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '姓名',
          field: 'workerName',
          sort: true,
          width: 100
        },
        // {
        //   title: '科室',
        //   field: 'orgName',
        //   sort: true
        // },
        {
          title: '工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '预警内容',
          field: 'warningText',
          sort: true
        },
        {
          title: '证件（合同）编号',
          width: 150,
          field: 'code',
          sort: true
        },
        {
          title: '失效日期',
          field: 'endDate',
          filterRange: true,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
          }
        },
        {
          title: '剩余天数',
          field: 'lastDays',
          filterRange: true,
          width: 130,
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', {
              domProps: {
                innerHTML: rowData.lastDays || '-'
              },
              style: {
                color: rowData.lastDays < 31 ? '#ed4014' : '#ff9900'
              }
            })
          }
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.edit) {
      loadSelectWorkerList(this)
    }
  },
  methods: {
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.needTry !== '是') {
        data.tryStartDate = null
        data.tryEndDate = null
      }
      if (data.endDate && this.timeBj(data.startDate, data.endDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '到期时间需要大于起始时间。'
        })
        throw Error()
      }
      if (data.stopDate && this.timeBj(data.startDate, data.stopDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '终止时间需要大于起始时间。'
        })
        throw Error()
      }
      if (data.tryStartDate && data.tryEndDate && this.timeBj(data.tryStartDate, data.tryEndDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '试用期结束时间需要大于试用期开始时间。'
        })
        throw Error()
      }
      return data
    },
    async clickTitleMenu () {
      await request.sendWarning({})
      this.$notice.success({
        title: '系统提示',
        desc: '语言通知任务已启动'
      })
    }
  },
  data () {
    return {
      request: {
        get: async (parm) => {
          parm.isNotLeave = 1
          let data = await loadData(request, this, parm)
          let workerMap = {}
          data.forEach(v => {
            v.lastDays = 10000
            if (v.endDate) {
              let ms = new Date(v.endDate).getTime() - new Date().getTime()
              v.lastDays = parseInt(ms / 1000 / 60 / 60 / 24) + 1
            }
            if (!workerMap[v.workerId]) {
              workerMap[v.workerId] = [v]
            } else {
              workerMap[v.workerId].push(v)
            }
          })
          let datas = []
          Object.keys(workerMap).forEach((workerId) => {
            let needWarning = workerMap[workerId].filter(v => v.lastDays > 90).length === 0
            if (needWarning) {
              workerMap[workerId].sort((a, b) => b.lastDays - a.lastDays)
              workerMap[workerId][0].warningText = workerMap[workerId][0].type + (workerMap[workerId][0].lastDays > 0 ? '即将过期' : '已过期')
              datas.push(workerMap[workerId][0])
            }
          })
          return datas
        },
        update: request.update,
        add: request.add
      }
    }
  }
}
</script>
